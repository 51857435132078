import React from 'react'
import useWindowDimensions from '../Hooks/useWindoDimensions'
import { Element } from 'react-scroll'
import profil from '../ressources/profil.jpg'

function Presentation() {
    const { height, width } = useWindowDimensions()

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: width > 1000 ? 'row' : 'column-reverse', marginTop: width > 1000 ? 0.1 * height : 0.05 * height, fontFamily: 'Poppins' }} >

            <div style={{ width: width > 1000 ? "40%" : "100%", marginTop: width > 1000 ? 0 : 0.1 * height }}>
                <img src={profil} alt="" style={{ marginLeft: "15%", width: "70%" }} />
            </div>
            <div style={{ width: width > 1000 ? 0.6 * width : width * 0.8, alignItems: 'center', margin: 'auto' }}>
                <Element name="presentation">
                    <div style={{ textAlign: 'center', fontSize: "4.5vh", textTransform: 'uppercase', fontWeight: 'bold', }} >Qui sommes nous ?</div>
                </Element>
                <div style={{ textAlign: 'justify', fontSize: "2.2vh", marginLeft: width > 1000 ? "20%" : "2.5vh", marginRight: width > 1000 ? "20%" : "2.5vh", marginTop: 0.05 * height }}>Je suis Nicolas, le pilote d'OverDrone. Après cinq années d'expérience de vol sur tout type de drones (FPV & stabilisés), j'ai décidé de mettre mes compétences au service de mes clients. Je veux permettre à tous de découvrir et redécouvrir des lieux et d'immortaliser des événements avec une perspective unique. <br /><br />  En tant que télépilote professionnel reconnu par la DGAC (Direction Générale de l'Aviation Civile), tous les vols effectués seront conformes à la réglementation. Tous les drones utilisés sont homologués et assurés. <br /><br /> Mon objectif est de fournir des solutions innovantes et sur mesure pour répondre aux besoins de chaque projet. Je m'engage à offrir un service personnalisé et professionnel pour garantir la satisfaction de mes clients. </div>
            </div>




        </div>
    )
}

export default Presentation