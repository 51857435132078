import React from 'react';
import logo from '../ressources/logo.png';
import '../App.css'
import useWindowDimensions from '../Hooks/useWindoDimensions';


function Footer() {
  const { height, width } = useWindowDimensions()
  return (
    <div style={{width:'100%', height:'10vh',backgroundColor:'#121a47', marginTop:'5vh',  fontFamily:"Poppins", display:'flex', flexDirection: width>1000? 'row':'column', justifyContent:'center'}}>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', color:'white'}}>© OverDrone 2023</div>
        {width>1000 && <div style={{ display:'flex', justifyContent:'center', alignItems:'center', color:'white', marginLeft:'5px', marginRight:'5px'}}> - </div>}
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', color:'white'}}>Création de Nicolas CHALANT</div>

    </div>
  );
}

export default Footer;
