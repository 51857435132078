import React from 'react';
import BearCarousel, {
    TBearSlideItemDataList,
    BearSlideItem
} from "bear-react-carousel";
import "bear-react-carousel/dist/index.css";
import montagne from '../ressources/montagne.jpg'
import plage from '../ressources/plage.jpg'
import evee from '../ressources/Evees_HD.jpg'
import useWindowDimensions from '../Hooks/useWindoDimensions';

const images = [
    { id: 1, image: plage },
    { id: 2, image: montagne },
    { id: 3, image: evee }
];

const bearSlideItemData: TBearSlideItemDataList = images.map((row) => {
    return {
        key: row.id,
        children: <BearSlideItem imageUrl={row.image} />
    };
});


function Slider() {
    const { height, width } = useWindowDimensions()
    return (
        <div>
        <BearCarousel
            data={bearSlideItemData}
            isEnableLoop
            isEnableNavButton
            isEnablePagination
            isEnableAutoPlay
            autoPlayTime={7000}
            aspectRatio={{ widthRatio: 16, heightRatio: 6.7 }}
        />
        </div>
    )
}

export default Slider
