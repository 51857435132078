import React from 'react'
import useWindowDimensions from '../Hooks/useWindoDimensions'
import CompareSlider from './CompareSlider'
import Slider from './Slider'
import piscine from '../ressources/piscine.jpg'
import { Element } from 'react-scroll'

function Prestations() {
    const { height, width } = useWindowDimensions()

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: 'column', marginTop: 0.1 * height, marginBottom: 0.1 * height, fontFamily: 'Poppins' }}>
            <Element name="prestation">
                <div style={{ textAlign: 'center', fontSize: "4.5vh", textTransform: 'uppercase', fontWeight: 'bold' }}>Nos prestations</div>
            </Element>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: "95%", margin: 'auto', marginTop: '4vh' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: width > 1000 ? 'row' : 'column', margin: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: width > 1000 ? 0.3 * width : 0.8 * width, height: '100%', margin: width > 1000 ? 'auto' : '2em auto' }}>
                        <div style={{ textAlign: 'center', fontSize: "4vh", fontWeight: "normal", margin: "auto" }}> Vidéo</div>
                        <div style={{ textAlign: 'justify', fontSize: "2.2vh", fontWeight: "lighter", padding: "2.5vh" }}> En utilisant des équipements de pointe, je capture des images aériennes spectaculaires en haute définition pour divers projets, tels que des événements corporatifs, des publicités, et des mariages. Je travaille en étroite collaboration avec mes clients pour créer des vidéos personnalisées qui mettent en valeur leur entreprise, leurs produits et leurs services.

</div>
                    </div>
                    <iframe width={width>1000? 0.6 * width : 0.8*width} height={width>1000? 0.3375 * width: 0.45 * width} src="https://www.youtube.com/embed/6n2ZEMgW6x4" title="My 2022 Drone Adventure - Exploring Heaven - SHOWREEL" style={{ border: 'none', margin: "auto" }} />
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: width > 1000 ? 'row' : 'column-reverse', margin: width > 1000 ? 'auto' : '2em auto' }}>
                    <img src={piscine} alt="" style={{ width: width>1000? 0.6 * width : 0.8*width, height: width>1000? 0.3375 * width: 0.45 * width, margin: '2em auto', marginTop: '2em' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', width: width > 1000 ? 0.3 * width : 0.8 * width, height: '100%', margin: 'auto' }}>
                        <div style={{ textAlign: 'center', fontSize: "4vh", fontWeight: "normal" }}> Photo</div>
                        <div style={{ textAlign: 'justify', fontSize: "2.2vh", fontWeight: "lighter", padding: "2.5vh" }}>Je propose des images de haute qualité pour une variété de projets, y compris des événements tels que des mariages, des projets immobiliers, et des inspections de toiture. Grâce à mon expertise technique et à mon approche créative pour capturer des images uniques et saisissantes, je travaille en étroite collaboration avec mes clients pour créer des images qui répondent à leurs besoins spécifiques. </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'center', fontSize: "4vh", fontWeight: "normal", margin: "auto" }}>Options supplémentaires :</div>
            <div style={{ textAlign: 'center', fontSize: "25px", width: width > 1000 ? 0.5 * width : 0.8 * width, margin:'auto' }}>
                <div style={{ textAlign: 'center', fontSize: "3vh", fontWeight: "normal", alignItems: 'center', margin: 'auto', marginTop: '3vh', marginBottom: '3vh' }}>Montage</div>
                <div style={{ textAlign: 'justify', fontSize: "2.2vh", fontWeight: "lighter", margin: "auto", padding: "2.5vh" }}>Vous cherchez à mettre en valeur vos nouveaux plans aériens grâce à une vidéo dynamique ? L'option montage est faite pour vous ! Vous nous donnez vos idées et nous vous donnerons un produit fini qui vous correspond. <br />Cette option comprend la colorimétrie.</div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <div style={{ textAlign: 'center', fontSize: "3vh", fontWeight: "normal", alignItems: 'center', margin: 'auto', marginTop: '3vh', marginBottom: '3vh' }}>Colorimétrie</div>
                <div style={{ textAlign: 'center', fontSize: "25px", width: width > 1000 ? 0.5 * width : 0.8 * width, margin:'auto' }}>
                    <div style={{ textAlign: 'justify', fontSize: "2.2vh", fontWeight: "lighter", padding: "2.5vh" }}>Améliorez la qualité de vos images aériennes grâce à notre option colorimétrie. Nous ajustons les couleurs, les contrastes et la luminosité pour obtenir un résultat esthétique et harmonieux. Que vous souhaitiez des images plus éclatantes, plus douces ou plus contrastées, nous sommes là pour répondre à vos besoins et donner vie à votre projet.</div>
                    <div style={{ textAlign: 'center', fontSize: "2.2vh", fontWeight: "lighter", width: width > 1000 ? 0.5 * width : 0.8 * width, margin: "auto", marginBottom: '3vh' }}>Avant / Après</div>
                </div>
                <CompareSlider />
            </div>


        </div>
    )
}

export default Prestations