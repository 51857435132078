import React, { useEffect } from 'react';
import './App.css';
import Contact from './Components/Contact';
import Equipment from './Components/Equipment';
import Footer from './Components/Footer';
import MainHeader from './Components/MainHeader';
import Presentation from './Components/Presentation';
import Prestations from './Components/Prestations';
import Slider from './Components/Slider';
import { Link, Element } from 'react-scroll'
import useWindowDimensions from './Hooks/useWindoDimensions';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

function App() {
  const { height, width } = useWindowDimensions()
  useEffect(() => {
   document.title = "OverDrone"
}, []);
  return (
    <div style={{width:"100%"}}>
      <Element name='top'/>
      <MainHeader/>
      <main className='App-main' style={{width: "100%"}}>
        <Slider/>
        <Presentation/>
        <Equipment/>
        <Prestations/>
        <Contact/>
        <Link to="top" spy={true} smooth={true} duration={500}><KeyboardArrowUpRoundedIcon style={{width: 30, height: 30, color:'white', padding:'0.5%', borderRadius:30, position:'sticky', bottom: 50, left: width*0.9, backgroundColor:'#454545', zIndex:100, cursor:'pointer'}}/></Link>
      </main>
      <Footer/>
      
    </div>
  );
}

export default App;
