import React, { useEffect, useState } from 'react';
import logo1 from '../ressources/logo.png';
import '../App.css'
import useWindowDimensions from '../Hooks/useWindoDimensions';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


function MainHeader() {
  const { height, width } = useWindowDimensions()
  const [smallScreenMode, setsmallScreenMode] = useState(width < 1000)
  const [openBurger, setopenBurger] = useState(false)

  useEffect(() => {
    if (width < 1000) {
      setsmallScreenMode(true)
    } else {
      setsmallScreenMode(false)
    }
  }, [width])



  return (
    <div style={{ height: 0.1 * height, background: 'white', width: "100%", fontFamily: 'Poppins' }}>
      <img src={logo1} alt="logo OverDrone" style={{ width: "15vh", position: 'absolute', left: '15px', top: '12px' }} />
      {smallScreenMode && <div style={{}}>
        <div style={{ position: 'absolute', right: '3vh', top: '3vh', cursor: "pointer" }} onClick={() => setopenBurger(!openBurger)}>
          {!openBurger && <MenuIcon />}
          {openBurger && <CloseIcon />}
        </div>
      </div>}
      {smallScreenMode && openBurger && <div style={{ display: "flex", flexDirection: 'column', position: 'absolute', right: '0', backgroundColor: 'white', zIndex: '20', marginTop: 0.1 * height }}>
        <Link activeClass="active" to="presentation" spy={true} smooth={true} duration={500} offset={-50} style={{ textDecoration: 'none', display: 'flex', textAlign: 'right', padding: '10px 30px', cursor: 'pointer' }}>
          Qui sommes nous ?
        </Link>
        <Link activeClass="active" to="equipment" spy={true} smooth={true} duration={500} offset={-50} style={{ textDecoration: 'none', display: 'flex', textAlign: 'right', padding: '10px 30px', cursor: 'pointer' }}>
          Notre matériel
        </Link>
        <Link activeClass="active" to="prestation" spy={true} smooth={true} duration={500} offset={-50} style={{ textDecoration: 'none', display: 'flex', textAlign: 'right', padding: '10px 30px', cursor: 'pointer' }}>
          Nos prestations
        </Link>
        <Link activeClass="active" to="contact" spy={true} smooth={true} duration={500} offset={-50} style={{ textDecoration: 'none', display: 'flex', textAlign: 'right', padding: '10px 30px', cursor: 'pointer' }}>
          Contact
        </Link>

      </div>}
      {!smallScreenMode && <div style={{ display: "flex", flexDirection: 'row', position: 'absolute', right: '0', height: 0.1 * height }}>
        <Link activeClass="active" to="presentation" spy={true} smooth={true} duration={500} offset={-50} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', paddingRight: '20px', paddingLeft: '20px', height: "100%", cursor: 'pointer' }}>
          Qui sommes nous ?
        </Link>
        <Link activeClass="active" to="equipment" spy={true} smooth={true} duration={500} offset={-50} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', paddingRight: '20px', paddingLeft: '20px', height: "100%", cursor: 'pointer' }}>
          Notre matériel
        </Link>
        <Link activeClass="active" to="prestation" spy={true} smooth={true} duration={500} offset={-50} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', paddingRight: '20px', paddingLeft: '20px', height: "100%", cursor: 'pointer' }}>
          Nos prestations
        </Link>
        <Link activeClass="active" to="contact" spy={true} smooth={true} duration={500} offset={-50} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', paddingRight: '20px', paddingLeft: '20px', height: "100%", cursor: 'pointer' }}>
          Contact
        </Link>

      </div>}


    </div>
  );
}

export default MainHeader;
