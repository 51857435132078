import React, { useEffect, useState } from 'react'
import useWindowDimensions from '../Hooks/useWindoDimensions'
import emailjs from '@emailjs/browser'
import logo from '../ressources/logo.png'
import { hover } from '@testing-library/user-event/dist/hover'
import { Element } from 'react-scroll'

function Contact() {
    const { height, width } = useWindowDimensions()

    const sendEmail = (e) => {
        e.preventDefault();
        console.log('firstname', e.target.firstname.value)
        console.log('lastname', e.target.lastname.value)
        console.log('email', e.target.email.value)
        console.log('message', e.target.message.value)

        if (validationData(e.target)) {
            emailjs.sendForm('service_l85eykh', 'template_6qzb6oq', e.target, 'AwUdWMe40Q99uiwF3')
            alert("Mail bien envoyé")
        }
    }

    const [isErrorData, setisErrorData] = useState(false)
    const [errorJSONData, seterrorJSONData] = useState({ 'firstnameError': '', 'lastnameError': '', 'emailError': '', 'messageError': '' })

    const validationData = (form) => {
        let isError = false;
        let errorJSON = { 'firstnameError': '', 'lastnameError': '', 'emailError': '', 'messageError': '' }
        if (form.lastname.value == '') {
            isError = true;
            errorJSON.lastnameError = 'Veuillez saisir votre nom'
        }
        if (form.firstname.value == '') {
            isError = true;
            errorJSON.firstnameError = 'Veuillez saisir votre prénom'
        }
        if (form.email.value == '') {
            isError = true;
            errorJSON.emailError = 'Adresse email incorrecte'
        }
        if (form.message.value == '') {
            isError = true;
            errorJSON.messageError = 'Veuillez saisir un message'
        }

        setisErrorData(isError)
        seterrorJSONData(errorJSON)
        return !isError;
    }

    useEffect(() => {
      console.log('width', width)
    }, [width])
    
    const [OnHover, setOnHover] = useState(false)

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', fontFamily: 'Poppins', flexDirection: width > 1000 ? 'row' : 'column' }}>
            <div style={{ margin: '3em auto', display: "flex", flexDirection:'column', width: width > 1000 ? 0.45 * width : 0.8 * width, marginBottom: 0.1 * height, backgroundColor: '#121a47', paddingTop: "2em", paddingBottom: "1em", borderRadius: '8px', boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }}>
                <Element name="contact">
                    <div style={{ textAlign: 'center', fontSize: "4.5vh", fontWeight: "bold", color: 'white' }}>Contactez nous</div>
                </Element>
                <div style={{ textAlign: 'justify', fontSize: "2vh", fontWeight: "lighter", color: "white", padding:'0.5vh 4vh'}}>N'hésitez pas à nous contacter pour toute demande de renseignement ou de devis, nous sommes là pour répondre à vos besoins et vous accompagner dans votre projet.</div>
                <form style={{ width: "80%", alignItems: 'center', margin: '1em auto', display: 'flex', flexDirection: 'column', }} onSubmit={sendEmail}>
                    <input type="text" style={{ width: width > 1000 ? 0.4 * width : 0.7 * width, height: "30px", marginTop: "10px", fontSize: "18px", borderRadius: '8px' }} placeholder="Nom" id="lastname" name="lastname" />
                    {isErrorData && <div style={{ color: 'red', textAlign: 'left', width: width > 1000 ? 0.4 * width : 0.7 * width }}>{errorJSONData.lastnameError}</div>}
                    <input type="text" style={{ width: width > 1000 ? 0.4 * width : 0.7 * width, height: "30px", marginTop: "10px", fontSize: "18px", borderRadius: '8px' }} placeholder="Prénom" id="firstname" name="firstname" />
                    {isErrorData && <div style={{ color: 'red', textAlign: 'left', width: width > 1000 ? 0.4 * width : 0.7 * width }}>{errorJSONData.firstnameError}</div>}
                    <input type="email" style={{ width: width > 1000 ? 0.4 * width : 0.7 * width, height: "30px", marginTop: "10px", fontSize: "18px", borderRadius: '8px' }} placeholder="Adresse mail" id="email" name="email" />
                    {isErrorData && <div style={{ color: 'red', textAlign: 'left', width: width > 1000 ? 0.4 * width : 0.7 * width }}>{errorJSONData.emailError}</div>}
                    <textarea rows={4} cols={50} style={{ width: width > 1000 ? 0.4 * width : 0.7 * width, resize: "none", height: "100px", marginTop: "10px", fontSize: "18px", borderRadius: '8px', fontFamily: 'Poppins' }} placeholder="Message" wrap="hard" id="message" name="message" />
                    {isErrorData && <div style={{ color: 'red', textAlign: 'left', width: width > 1000 ? 0.4 * width : 0.7 * width }}>{errorJSONData.messageError}</div>}
                    <button type='submit' style={{ marginTop: "20px", width: "100px", height: "40px", fontSize: "20px", borderRadius: "10px", border: 0, color: !OnHover ? '#121a47' : "white", backgroundColor: !OnHover ? "white" : '#121a47', border: !OnHover ? 'none' : 'white 1px solid', cursor: 'pointer' }} onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>Valider</button>
                </form>
            </div>
            <div style={{ width: width > 1000 ? 0.2 * width : 0.6 * width, margin: 'auto' }}>
                <img src={logo} alt="logo OverDrone" style={{ width: '80%', marginLeft: '10%' }} />
            </div>
        </div>
    )
}

export default Contact