import React from 'react';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import useWindowDimensions from '../Hooks/useWindoDimensions';
import avant1 from '../ressources/avant1.jpg'
import apres1 from '../ressources/apres1.jpg'
import avant2 from '../ressources/avant2.jpg'
import apres2 from '../ressources/apres2.jpg'

function CompareSlider() {
    const { height, width } = useWindowDimensions()

    return (
        <div style={{width:.8*width, margin:'auto'}}>
            <ImgComparisonSlider style={{width:'100%', height:'100%', margin:'auto'}}>
                <img slot="first" src={avant1} style={{width:'100%'}}/>
                <img slot="second" src={apres1} style={{width:'100%'}}/>
            </ImgComparisonSlider>
        </div>
    );
}

export default CompareSlider;