import React, { useEffect, useState } from 'react'
import useWindowDimensions from '../Hooks/useWindoDimensions'
import protek from '../ressources/protek.jpg'
import cinqpouces from '../ressources/cinqpouces.jpg'
import mavic from '../ressources/mavic.jpg'
import ReactCardFlip from 'react-card-flip';
import { Element } from 'react-scroll'

function Equipment() {
    const { height, width } = useWindowDimensions()
    const [flippedCardOne, setflippedCardOne] = useState(false)
    const [flippedCardTwo, setflippedCardTwo] = useState(false)
    const [flippedCardThree, setflippedCardThree] = useState(false)


    return (
        <div style={{ width: "100%", display: "flex", fontFamily: 'Poppins', flexDirection: 'column', marginTop: 0.05 * height, marginBottom: 0.05 * height, backgroundColor: "#121a47", paddingTop: "2em", paddingBottom: "1em", boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }}>
            <Element name="equipment">
                <div style={{ textAlign: 'center', fontSize: "4.5vh", textTransform: 'uppercase', fontWeight: 'bold', padding: '2vh', color: 'white', }}>Notre matériel</div>
            </Element>
            <div style={{ textAlign: 'center', fontSize: "2.2vh", marginTop: '25px', color: 'white', width: width * 0.8, padding: '2.5vh', margin: 'auto' }}>Nous avons la solution adaptée à vos besoins, n'hésitez pas à nous contacter pour en discuter</div>
            <div style={{ width: "100%", display: "flex", flexWrap: 'wrap', justifyContent: 'space-around' }}>
                <ReactCardFlip isFlipped={flippedCardTwo} containerStyle={{ width: "300px", height: "300px", marginTop: 0.05 * height }}>
                    <div style={{ width: "100%", height: "100%", boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} onClick={() => setflippedCardTwo(!flippedCardTwo)}>
                        <img src={cinqpouces} alt="drone fpv" style={{ width: "100%", borderRadius: '20px' }} />
                    </div>
                    <div style={{ width: "100%", height: "100%", borderRadius: '20px', backgroundColor: 'white', boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} onClick={() => setflippedCardTwo(!flippedCardTwo)}>
                        <div style={{ textAlign: 'center', fontSize: "30px", fontWeight: "bold", paddingTop: "1em", paddingBottom: "0.5em", borderBottom: '1px solid ', width: '80%', marginLeft: "10%", color: '#121a60', }}>Drone FPV 5"</div>
                        <div style={{ textAlign: 'center', fontSize: "17px", paddingLeft: "0.5em", paddingRight: "0.5em", paddingTop: "0.5em" }}> Pour vos plans dynamiques pouvant atteindre 130km/h <br /> <br /> Équipé d'une GoPro Hero 11, il vous fournira des images d'une grande qualité (jusqu'à 5.3k)</div>
                    </div>
                </ReactCardFlip>
                <ReactCardFlip isFlipped={flippedCardThree} containerStyle={{ width: "300px", height: "300px", marginTop: 0.05 * height }}>
                    <div style={{ width: "100%", height: "100%", boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} onClick={() => setflippedCardThree(!flippedCardThree)}>
                        <img src={mavic} alt="drone mavic" style={{ width: "100%", borderRadius: '20px' }} />
                    </div>
                    <div style={{ width: "100%", height: "100%", borderRadius: '20px', backgroundColor: 'white', boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} onClick={() => setflippedCardThree(!flippedCardThree)}>
                        <div style={{ textAlign: 'center', fontSize: "30px", fontWeight: "bold", paddingTop: "0.7em", paddingBottom: "0.5em", borderBottom: '1px solid ', width: '80%', marginLeft: "10%", color: '#121a60', }}>Mavic Pro</div>
                        <div style={{ textAlign: 'center', fontSize: "18px", paddingLeft: "0.5em", paddingRight: "0.5em", paddingTop: "0.5em" }}>Pour vos plans stabilisés et la prise de photo aérienne<br /> <br /> Rendu vidéo et photo jusqu'en 4k !</div>
                    </div>
                </ReactCardFlip>

                <ReactCardFlip isFlipped={flippedCardOne} containerStyle={{ width: "300px", height: "300px", marginTop: 0.05 * height }}>
                    <div style={{ width: "100%", height: "100%", boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} onClick={() => setflippedCardOne(!flippedCardOne)}>
                        <img src={protek} alt="drone protek35" style={{ width: "100%", height: "100%", borderRadius: '20px' }} />
                    </div>
                    <div style={{ width: "100%", height: "100%", borderRadius: '20px', backgroundColor: 'white', boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} onClick={() => setflippedCardOne(!flippedCardOne)}>
                        <div style={{ textAlign: 'center', fontSize: "30px", fontWeight: "bold", paddingTop: "0.7em", paddingBottom: "0.5em", borderBottom: '1px solid ', width: '80%', marginLeft: "10%", color: '#121a60', }}>Cinéwhoop</div>
                        <div style={{ textAlign: 'center', fontSize: "17px", paddingLeft: "0.5em", paddingRight: "0.5em", paddingTop: "0.5em" }}>Pour vos plans en intérieur ou des vols de proximité en toute sécurité <br /> <br />Équipé d'une GoPro Hero 11, il vous fournira des images d'une grande qualité (jusqu'à 5.3k)</div>
                    </div>
                </ReactCardFlip>


            </div>

            <div style={{ textAlign: 'center', fontSize: "2.2vh", marginTop: '25px', color: 'white', paddingBottom: '1em' }}>(Appuyer sur les images pour plus d'informations)</div>
            {/* https://github.com/AaronCCWong/react-card-flip */}
        </div>
    )
}

export default Equipment